import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const ronakExpertisesData = allData.bansiExpertisesData;

const userData = {
    name: "Bansi Pipaliya",
    designation: "Project Manager",
    image: '../assets/img/ronak-pipaliya/bansi-pipaliya.png',
    tag: `bansi-pipaliya`,
    phones: ['(+91) 9624819717'],
    // emails: ['bansi@vasundhara.io', 'bansi.vasundhara@gmail.com'],
    emails: ['bansi@vasundhara.io'],
    linkedin: 'https://in.linkedin.com/in/bansi-savani-b9a55111b',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    description: [
        `A project manager at Vasundhara Infotech who has extensive knowledge in software development. She has 7 years of experience in IT Field.`,
        `She aims for end-to-end project delivery with user-friendly applications.`,
        `Her extensive experience in delivering end-to-end solutions to complex projects has made her work easy.`,
        `She manages to monitor the work quality and handles the business development team to grab new opportunities effectively.`,
        `Her strategic mind executes high-impact projects and high-visibility initiatives.`,
        `She knows how to create a collaborative team culture, which will help with productivity.`,
        `She understands the problems of developers and helps them find solutions.`,
        `Communicates with the clients about their requirements and brainstorm with the team on their ideas to implement them successfully.`,
        `Creates project timelines with clients and teams to ensure a clear understanding of the project's estimated complexity and duration.`,
        `She assigns different tasks to employees, according to their capabilities. But before that, she analyses the project carefully from each aspect.`,
    ]
}

function BansiPipaliya() {
    const [portFolio, setPortFolio] = useState([]);

    useEffect(() => {
        allApiData.aosinit();

        allApiData.allPortfolios(1, 'App Development', 'app_type').then((data) => {
            setPortFolio(data.data);
        });
    }, [])

    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={false}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=app_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        typeWise='app_type'
                        isPagination={false}
                        portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={ronakExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default BansiPipaliya